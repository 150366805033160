export interface EventSequence {
    timeGMT: string;
    sequenceNumber: number;
}

export const generateEventSequence = (): EventSequence => {    
    const eventSequence = {
        timeGMT: new Date().toISOString(),
        sequenceNumber: Date.now(),
    };

    return eventSequence;
};
